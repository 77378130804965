//jsxhook
import {
  ImageVariants,
  ImgixAspectRatio,
  ImgixImage,
  LinkVariants
} from "@sixty-six-north/ui-system"
import {
  useCartNotification,
  useCartNotificationState
} from "cart/CartServiceHook"
import { useCartLink } from "components/Links"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Link } from "theme-ui"
import { ShoppingBagNotification, ShoppingBagToast } from "../../../bag"
import { useRouter } from "../../../routing/useRouter"

export const CartNotifications: FC = () => {
  const cartNotificationApi = useCartNotification()
  const cartNotificationState = useCartNotificationState()
  const cartLink = useCartLink()
  const router = useRouter()
  const { t } = useTranslation("layout")

  return (
    <ShoppingBagToast
      showNotification={!!cartNotificationState.product}
      timeout={6000}
      clearNotification={() => {
        cartNotificationApi.clear()
      }}
    >
      {!!cartNotificationState.product && (
        <ShoppingBagNotification
          product={cartNotificationState.product}
          image={
            <Box
              sx={{ width: 45, variant: `images.${ImageVariants.ratio_3x4}` }}
            >
              <ImgixImage
                imageUrl={cartNotificationState.product.imageUrl}
                imageAlt={cartNotificationState.product.imageAlt}
                width={45}
                height={60}
                sizes={"3rem"}
                aspectRatio={ImgixAspectRatio.ar3x4}
              />
            </Box>
          }
          link={
            <Link
              href={"#"}
              sx={{
                variant: `links.${LinkVariants.primaryButton}`,
                width: "100%"
              }}
              onClick={() => {
                cartNotificationApi.clear()
                router.push(cartLink.href, cartLink.as)
              }}
            >
              {t("viewShoppingBag", { defaultValue: "View Shopping Bag" })}
            </Link>
          }
        />
      )}
    </ShoppingBagToast>
  )
}
