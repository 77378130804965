//jsxhook
import { Flex } from "theme-ui"
import { useState, useEffect, ReactNode } from "react"
import { StandardXAxisPadding } from "@sixty-six-north/ui-system"
import useScrollPosition from "@react-hook/window-scroll"
import React from "react"
import { useLayoutState } from "layout/LayoutStateHooks"

export const NavigationBar: React.FC<{
  children?: ReactNode
}> = props => {
  const { menuOpen, transparentMenu } = useLayoutState()

  let solidMenu = transparentMenu ? false : true
  if (menuOpen) solidMenu = true

  const [navScrolled, setNavScrolled] = useState(false)
  const scrollY = useScrollPosition(60)

  // adds a backgrond depending where people have scrolled for the fullbleed content componetns
  useEffect(() => {
    if (!transparentMenu) return
    if (scrollY > 50) setNavScrolled(true)
    if (scrollY < 50) setNavScrolled(false)
  }, [scrollY])

  return (
    <Flex
      role="navigation"
      aria-label="Main"
      className={
        solidMenu ? "66__root solid-menu" : "66__root transparent-menu"
      }
      sx={{
        width: "100%",
        height: 88,
        borderBottomStyle:
          navScrolled || solidMenu || menuOpen ? "solid" : "none",
        borderBottomWidth: "1px",
        borderBottomColor: "grey.4",
        px: StandardXAxisPadding,
        position: ["sticky", null, null, "relative"],
        top: "0px",
        left: "0px",
        right: "0px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flexShrink: 1,
        background:
          navScrolled || solidMenu
            ? "white.0"
            : "linear-gradient(180deg, rgba(5, 30, 52, 0.66) -20% ,rgba(5, 30, 52, 0)  100%)",
        transition: "background 0.2s ease-in",

        color: navScrolled || solidMenu || menuOpen ? "grey.0" : "white.0",

        ":hover": {
          background: "white.0",
          color: "grey.0",
          "& > li a": {
            color: navScrolled || solidMenu || menuOpen ? "grey.0" : "white.0",
            "&:hover": {
              color: "blue.0"
            }
          }
        },
        "& .active a": {
          color: "blue.0",
          borderBottom: "1px solid"
        }
      }}
    >
      {props.children}
    </Flex>
  )
}
