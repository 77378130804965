//jsxhook
import React, { FC, ReactNode } from "react"
import { ThemeUIStyleObject } from "theme-ui"

interface Props {
  className?: string
  id?: string
  isForward?: boolean
  isChildren?: boolean
  isBack?: boolean
  role?: string
  menuOpen?: boolean
  secondMenuOpen?: boolean
  sx?: ThemeUIStyleObject
  children?: ReactNode
}

export const NavItem: FC<Props> = ({
  role = "none",
  id,
  isBack = false,
  children,
  ...props
}) => {
  const Styles: ThemeUIStyleObject = {
    display: ["inline-block", null, null, isBack ? "none" : "inline-block"],
    pb: [12, null, null, 0]
  }

  return (
    <li id={id} role={role} sx={Styles} {...props}>
      {children}
    </li>
  )
}
