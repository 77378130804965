import { ParsedUrlQueryInput } from "querystring"
import { useLocaleAwareLinks } from "../i18n/StoreHooks"
import { useTranslation } from "react-i18next"
import { useTopLevelNavigation } from "../layout/NavigationHooks"
import { LocalizedString } from "@commercetools/platform-sdk"
import { UrlObject } from "url"

const domainCategories: Record<string, LocalizedString> = {
  men: { en: "men", is: "karlar" },
  women: { en: "women", is: "konur" },
  kids: { en: "kids", is: "krakkar" }
}
const validTopLevelCategories = Object.keys(domainCategories)

export type MenuItem = {
  href?: string | null
  label: string
  query?: string | ParsedUrlQueryInput | null
}
export const useAdditionalFilterLinks = () => {
  const linkBuilder = useLocaleAwareLinks()
  const { t } = useTranslation("navigation")
  const additionalFilters = useTopLevelNavigation().allowed_product_filters

  const generate = (
    topLevelMenu: string,
    slugAppendix: LocalizedString
  ): UrlObject =>
    linkBuilder.localisedLink({
      en: `${domainCategories[topLevelMenu].en}${slugAppendix.en}`,
      is: `${domainCategories[topLevelMenu].is}${slugAppendix.is}`
    }).href

  return {
    generateCollectionMenuItems: (topLevelMenu: string): MenuItem[] => {
      const normalisedTopLevelMenu = topLevelMenu.replace(/-+$/, "")
      if (!validTopLevelCategories.includes(normalisedTopLevelMenu)) {
        return []
      }
      return Object.entries({
        ...(additionalFilters.length > 0 && {
          sale: {
            ...generate(normalisedTopLevelMenu, {
              en: `/all`,
              is: `/allur`
            }),
            query: `vf=${additionalFilters[0]}`
          }
        })
      }).map(([key, value]) => ({
        label: t(key),
        href: value.pathname,
        query: value.query
      }))
    }
  }
}
