import { useContext } from "react"
import { MessageBarContext } from "./MessageBarContext"

export interface GlobalMessageBarItem {
  id: string | number
  title: string | null
  link?: {
    id?: string
    label: string
    href: string
  }
}

export const useMessageBarContext = () => useContext(MessageBarContext)

export const useMessageBarMessages = (): GlobalMessageBarItem[] =>
  useMessageBarContext().messages || []
