//jsxhook

import React, { FC, ReactNode } from "react"
import { ThemeUIStyleObject } from "theme-ui"

interface Props {
  ariaLabel: string
  id: string
  menuOpen: boolean
  secondMenuOpen: boolean
  children?: ReactNode
}

export const Nav: FC<Props> = ({
  children,
  menuOpen,
  secondMenuOpen,
  ariaLabel,
  id
}) => {
  const Styles: ThemeUIStyleObject = {
    display: "flex",

    width: ["100%", null, null, "auto"],
    height: [menuOpen ? "calc(100vh - 5.5rem)" : "auto", null, null, "auto"],
    flexDirection: ["column", null, null, "row"],
    p: 0,
    overflowY: ["scroll", null, null, "visible"],
    overflowX: ["hidden", null, null, "visible"],
    position: ["absolute", null, null, "unset"],
    top: 88,
    left: 0
  }

  return (
    <nav id={id} aria-label={ariaLabel} sx={Styles}>
      {children}
    </nav>
  )
}
