import { symbolForLocation } from "@sixty-six-north/i18n"
import * as NavigationMenu from "@radix-ui/react-navigation-menu"
import {
  FontWeight,
  Label3,
  pageTransitionProperties,
  Shadows,
  SmallArrowDown,
  StandardXAxisPadding
} from "@sixty-six-north/ui-system"
import { AnimatePresence, m, useReducedMotion } from "framer-motion"
import { DEFAULT_REGION, REGIONS } from "i18n/Region"
import {
  useStoreContext,
  useStoreFromCookie,
  useStoreFromGeography
} from "i18n/StoreHooks"
import Link from "next/link"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Flex } from "theme-ui"
import {
  GlobalMessageBarItem,
  useMessageBarMessages
} from "../layout/MessageBarHooks"
import { PrismicDocumentLink } from "../prismic/slices/PrismicLink"
import { useCountrySelection } from "./Footer"

export const MESSAGE_BAR_ELEMENT_ID = "global-message-bar"
const LONG_MESSAGE_CHARACTER_THRESHOLD = 120

const RegionNavigation = ({ currentCountry, onChange }) => {
  const { t } = useTranslation()
  const symbol = symbolForLocation(currentCountry)

  return (
    <NavigationMenu.Root
      role="region"
      aria-label="Change shipping destination"
      sx={{ position: "relative" }}
    >
      <NavigationMenu.List sx={{ listStyle: "none" }}>
        <NavigationMenu.Item>
          <NavigationMenu.Trigger
            sx={{ background: "none", border: 0, height: "40px" }}
          >
            <Label3
              sx={{
                color: "white.0",
                top: [0, null, null, 2],
                position: "relative"
              }}
            >
              <span sx={{ display: ["none", null, null, "inline-block"] }}>
                {t(`footer:shippingTo`)} {t(`footer:${currentCountry}`)}{" "}
                {`(${symbol})`}
              </span>
              <span
                sx={{
                  display: ["inline-block", null, null, "none"],
                  border: "1px solid white",
                  borderRadius: 12,
                  px: 4
                }}
              >
                {symbol}
              </span>
              <SmallArrowDown sx={{ height: 7, ml: 4 }} />
            </Label3>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content
            sx={{
              zIndex: 99,
              backgroundColor: "white",
              width: 240,
              position: "absolute",
              top: "calc(100% - 3px)",
              right: 0,
              boxShadow: Shadows.small,
              mt: 4
            }}
          >
            {REGIONS.map((key, index) => {
              const { country } = key
              const href = country === currentCountry ? {} : { href: "#" }
              return (
                <NavigationMenu.Link
                  key={index}
                  onClick={() => onChange(country)}
                  tabIndex={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                    py: 12,
                    px: 12,
                    textDecoration: "none",
                    color: "grey.0"
                  }}
                  {...href}
                >
                  <Label3
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: FontWeight.textBold,
                      padding: 0,
                      textDecoration:
                        country === currentCountry ? "underline" : "none"
                    }}
                  >
                    <img
                      src={`/flags/${country}.svg`}
                      width="19"
                      height="19"
                      sx={{ mr: 8 }}
                    />
                    {t(`footer:${country}`)} ({symbolForLocation(country)})
                  </Label3>
                </NavigationMenu.Link>
              )
            })}
          </NavigationMenu.Content>
        </NavigationMenu.Item>
        <NavigationMenu.Indicator
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            height: 10,
            top: "100%",
            zIndex: 1
          }}
        >
          <div
            sx={{
              position: "relative",
              top: 0,
              right: 8,
              backgroundColor: "white",
              width: 12,
              height: 12,
              transform: "rotate(45deg)",
              borderTopLeftRadius: 2
            }}
          />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>
    </NavigationMenu.Root>
  )
}

export function GlobalMessageBar() {
  const [messageIndex, setCurrentMessageIndex] = useState(0)
  const messages = useMessageBarMessages()
  const { t } = useTranslation()
  const setCountry = useCountrySelection()
  const storeCountry = useStoreContext().country
  const storeFromCookie = useStoreFromCookie()
  const storeFromGeography = useStoreFromGeography()
  const store = useStoreContext()
  const freeShippingThreshold = store.freeShippingThreshold
  const defaultMessage: GlobalMessageBarItem = {
    id: 0,
    title: t("layout:freeShippingMessage", {
      amount: freeShippingThreshold
    })
  }

  if (!messages.length) messages.push(defaultMessage)

  const defaultValue =
    storeCountry ||
    storeFromCookie?.country ||
    storeFromGeography?.country ||
    DEFAULT_REGION.country

  const [currentCountry, setCurrentCountry] = useState(defaultValue)

  const reducedMotion = useReducedMotion()

  useEffect(() => {
    setCurrentCountry(storeCountry)
  }, [storeCountry])

  const handleRegionChange = country => {
    setCurrentCountry(country)
    if (currentCountry !== country) setCountry(country)
  }

  const MessageLink = ({ message }) => {
    if (!message.link.id && !message.link.href) return null
    return (
      <Label3
        sx={{
          a: { color: "inherit" }
        }}
        aria-label={message.title?.replace(/\.+$/, "") || ""}
      >
        {message.link.id && (
          <PrismicDocumentLink key={message.id} documentId={message.link?.id}>
            {message.link?.label}
          </PrismicDocumentLink>
        )}
        {!message.link.id && message.link.href && (
          <Link href={message.link.href} passHref>
            {message.link?.label || ""}
          </Link>
        )}
      </Label3>
    )
  }

  const py = 6
  const my = 30

  return (
    <Flex
      id={MESSAGE_BAR_ELEMENT_ID}
      role="banner"
      sx={{
        width: "100%",
        backgroundColor: "blue.1",
        lineHeight: 1,
        px: StandardXAxisPadding,
        py: `${py}px`
      }}
    >
      <Flex
        sx={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          height: ["44px", null, null, "28px"]
        }}
      >
        <AnimatePresence mode={"wait"}>
          <m.div
            role="marquee"
            initial={{
              y: reducedMotion ? 0 : 22,
              opacity: reducedMotion ? 1 : 0
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: pageTransitionProperties
            }}
            exit={{
              y: reducedMotion ? 0 : -8,
              opacity: reducedMotion ? 1 : 0,
              transition: {
                ...pageTransitionProperties,
                delay: 5
              }
            }}
            key={messages[messageIndex]?.id}
            onAnimationComplete={() => {
              let nextMessageIndex = messageIndex + 1
              if (nextMessageIndex >= messages.length) {
                nextMessageIndex = 0
              }
              setTimeout(
                () => setCurrentMessageIndex(nextMessageIndex),
                reducedMotion ? 5000 : 0
              )
            }}
            sx={{
              overflow: "hidden",
              height: "100%",
              color: "white.0"
            }}
          >
            {messages.map((message, idx) => {
              const isMessageTitleLongerThanThreshold =
                message.title &&
                message.title.length > LONG_MESSAGE_CHARACTER_THRESHOLD
              const marginOffset = my

              return (
                <Box
                  key={`message-${idx}`}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    position: "relative",
                    height: ["44px", null, null, "28px"],
                    top: [
                      `-${
                        marginOffset +
                        marginOffset * messageIndex +
                        44 * messageIndex
                      }px`,
                      null,
                      null,
                      `-${
                        marginOffset +
                        marginOffset * messageIndex +
                        28 * messageIndex
                      }px`
                    ],
                    my: `${my}px`
                  }}
                >
                  <Label3
                    sx={{
                      mr: 4,
                      ":after": {
                        content:
                          message.link &&
                          message.title &&
                          !isMessageTitleLongerThanThreshold
                            ? '"."'
                            : "''"
                      }
                    }}
                  >
                    {message.title?.replace(/\.+$/, "")}
                    {message.link && isMessageTitleLongerThanThreshold && (
                      <>
                        {". "}
                        <MessageLink message={message} />
                      </>
                    )}
                  </Label3>
                  {message.link && !isMessageTitleLongerThanThreshold && (
                    <MessageLink message={message} />
                  )}
                </Box>
              )
            })}
          </m.div>
        </AnimatePresence>
        <Box
          sx={{
            flex: "0 auto",
            minWidth: [52, null, null, 230],
            textAlign: "right",
            position: "relative",
            top: -2,
            button: { padding: 0 }
          }}
        >
          <RegionNavigation
            onChange={handleRegionChange}
            currentCountry={currentCountry}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
