import { Box, Flex } from "theme-ui"
import { ButtonVariants, HamburgerIcon } from "@sixty-six-north/ui-system"
import React, { ReactNode } from "react"

interface HamburgerProps {
  toggle?: (ev: React.MouseEvent<HTMLDivElement>) => void
  tabIndex?: number
  children?: ReactNode
}

export const HamburgerButton: React.FC<HamburgerProps> = ({
  toggle = () => 0,
  children,
  tabIndex,
  ...props
}) => (
  <Box
    as="button"
    onClick={ev => toggle(ev)}
    sx={{ variant: `buttons.${ButtonVariants.accessible}`, color: "inherit" }}
    tabIndex={tabIndex}
    {...props}
  >
    <Flex
      sx={{
        flex: 1,
        alignItems: "center",
        "& > svg": {
          mr: 8
        }
      }}
    >
      <HamburgerIcon />
      {children}
    </Flex>
  </Box>
)
