import { Country } from "@sixty-six-north/i18n"
import { Language } from "@sixty-six-north/i18n"

export const languageForCountry = (country: Country): Language => {
  switch (country) {
    case Country.is:
      return Language.is
    default:
      return Language.en
  }
}

export class LanguageResolver {
  public resolve(
    country: Country,
    cookies?: Record<string, string>
  ): Promise<Language> {
    return Promise.resolve(languageForCountry(country))
  }
}
