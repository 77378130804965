//jsxhook
import {
  enterObjectTransitionProperties,
  zIndices
} from "@sixty-six-north/ui-system"
import { AnimatePresence, m } from "framer-motion"
import React, { FC, ReactNode } from "react"
import { ThemeUIStyleObject } from "theme-ui"
import { useIsMobile } from "../../../utils/uiHelpers"

interface Props {
  id?: string
  ariaLabel: string
  menuOpen?: boolean
  secondMenuOpen?: boolean
  children?: ReactNode
}

export const MainList: FC<Props> = ({
  id,
  ariaLabel = "Main menu",
  menuOpen,
  secondMenuOpen,
  children
}) => {
  // ok this should just open the tray, not handle the movement of its children
  const isMobile = useIsMobile()
  const variants = {
    closed: {
      opacity: isMobile ? 0 : 1,
      x: isMobile ? "-100vw" : 0,
      transition: {
        stiffness: enterObjectTransitionProperties.stiffness,
        damping: enterObjectTransitionProperties.damping,
        delay: isMobile ? 0.2 : 0,
        duration: isMobile ? 0.3 : 0,
        type: isMobile ? "spring" : "none"
      },
      transitionEnd: {
        x: isMobile ? "-100vw" : 0,
        opacity: 1
      }
    },
    open: {
      opacity: 1,
      x: isMobile ? "1.5rem" : 0,
      transition: {
        stiffness: enterObjectTransitionProperties.stiffness,
        damping: enterObjectTransitionProperties.damping,
        delay: isMobile ? 0.2 : 0,
        duration: isMobile ? 0.3 : 0,
        type: isMobile ? "spring" : "none"
      },
      transitionEnd: {
        x: isMobile ? "1.5rem" : 0,
        opacity: 1
      }
    },
    firstMenuClosedSecondMenuOpen: {
      opacity: 1,
      x: isMobile ? "1.5rem" : 0,
      transition: {
        stiffness: enterObjectTransitionProperties.stiffness,
        damping: enterObjectTransitionProperties.damping,
        delay: 0,
        duration: isMobile ? 0.3 : 0,
        type: isMobile ? "spring" : "none"
      },
      transitionEnd: {
        x: "unset",
        opacity: 1
      }
    }
  }

  let menuOpenAnimation = "closed"

  if (menuOpen && secondMenuOpen === false) {
    menuOpenAnimation = "open"
  } else if (menuOpen && secondMenuOpen) {
    menuOpenAnimation = "firstMenuClosedSecondMenuOpen"
  } else if (menuOpen === false) {
    menuOpenAnimation = "closed"
  } else {
    menuOpenAnimation = "closed"
  }

  const Styles: ThemeUIStyleObject = {
    display: ["flex"],
    flexDirection: ["column", null, null, "row"],
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: ["flex-start", null, null, "center"],
    opacity: [0, 0, 0, 1],
    pl: [0, null, null, 24],

    width: "100%",
    flexShrink: 1,
    height: ["calc(100vh - 5.5rem)", null, null, "4rem"],
    zIndex: [
      menuOpen && secondMenuOpen ? zIndices.page : zIndices.trayMenu,
      null,
      null,
      zIndices.megaMenu
    ],
    listStyle: "none",
    position: ["absolute", null, null, "unset"],
    transform: "unset",
    // bg: `${Colors.white}.0`,
    "& > li": {
      mr: [0, null, null, 24]
    }
  }
  //const AnimatedBox = motion(Box)

  return (
    <m.ul
      id={id}
      role="menubar"
      aria-label={ariaLabel}
      sx={Styles}
      animate={menuOpenAnimation}
      variants={variants}
      initial={{ x: 0, opacity: 1 }}
      style={{ transform: "unset" }}
    >
      {children}
    </m.ul>
  )
}
