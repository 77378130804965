//jsxhook

import { LinkVariants, BagIcon } from "@sixty-six-north/ui-system"
import { ShoppingBagIndicator } from "bag"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "theme-ui"
import { CartCalculator } from "../../cart/CartCalculator"
import { useCartState } from "../../cart/CartServiceHook"
import Link from "../../components/Link"
import { useCartLink } from "../../components/Links"

/**
 * Shows the link to the shopping basket in the header
 */
const CartHeaderLink = ({ onClick }: { onClick(): void }) => {
  const cart = useCartState()
  const cartLink = useCartLink()
  const { t } = useTranslation()

  const [items, setItems] = useState(0)

  // Using an effect to force a re-rending of the pill on first page load and when the cart changes
  useEffect(() => {
    setItems(CartCalculator.totalItems(cart))
  }, [cart])

  return (
    <Link
      {...cartLink}
      onClick={() => {
        onClick()
      }}
      aria-label={t("bag")}
      sx={{
        position: "relative",
        variant: `links.${LinkVariants.navBar}`,
        borderBottomColor: "transparent",
        ":hover": { borderBottomColor: "transparent" }
      }}
    >
      <Box>
        <BagIcon />
      </Box>

      <Box sx={{ position: "absolute", top: 0, right: 10 }}>
        <ShoppingBagIndicator highlight={false} itemsInShoppingBag={items} />
      </Box>
    </Link>
  )
}

export default CartHeaderLink
