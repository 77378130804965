import {
  enterObjectTransitionProperties,
  LinkVariants
} from "@sixty-six-north/ui-system"
import { m } from "framer-motion"
import { ParsedUrlQueryInput } from "querystring"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { FC, ReactNode } from "react"
import { ThemeUIStyleObject } from "theme-ui"
import { useIsDesktop } from "utils/uiHelpers"

interface Props {
  id?: string
  role?: string
  href: string
  isActive?: boolean
  isBack?: boolean
  menuOpen?: boolean
  query?: ParsedUrlQueryInput
  secondMenuOpen?: boolean
  isChildren?: boolean
  ariaHaspopup?: boolean
  ariaControls?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLAnchorElement>) => void
  children?: ReactNode
}

export const NavItemLink: FC<Props> = ({
  id,
  role = "menuitem",
  href,
  isActive = false,
  isBack,
  isChildren = false,
  menuOpen = false,
  secondMenuOpen = false,
  onClick,
  query = {},
  onKeyDown,
  ariaHaspopup,
  ariaControls,
  children
}) => {
  const isDesktop = useIsDesktop()
  const router = useRouter()

  const Styles: ThemeUIStyleObject = {
    variant: `links.${LinkVariants.navBar}`,
    pt: 0,
    mt: 12,
    display: "block"
  }

  // is the first menu, and the first menu is open
  // is the first menu, and the second menu is closed
  // is the first menu, and the second menu is open
  // is the second menu, and the second menu is open
  // is the second menu, and the second menu is closed
  let menuOpenAnimation = "firstMenuOpenSecondMenuClosed"
  // what animation
  if (menuOpen && secondMenuOpen === false && isChildren === false) {
    menuOpenAnimation = "firstMenuOpenSecondMenuClosed"
  } else if (menuOpen && secondMenuOpen && isChildren === false) {
    menuOpenAnimation = "firstMenuClosedSecondMenuOpen"
  } else if (menuOpen && secondMenuOpen && isChildren) {
    menuOpenAnimation = "secondMenuOpen"
  } else if (menuOpen && secondMenuOpen === false && isChildren) {
    menuOpenAnimation = "secondMenuClosed"
  } else if (
    menuOpen === false &&
    secondMenuOpen === false &&
    isChildren === false
  ) {
    menuOpenAnimation = "firstMenuClosed"
  }

  const motionVariants = isDesktop
    ? {
        firstMenuOpenSecondMenuClosed: {
          opacity: 1,
          transition: {
            duration: 0
          }
        },
        firstMenuClosedSecondMenuOpen: {
          opacity: 1,
          transition: {
            duration: 0
          }
        },
        firstMenuClosed: {
          opacity: 1,
          transition: {
            duration: 0
          }
        },
        secondMenuClosed: {
          opacity: 1,
          transition: {
            duration: 0
          }
        },
        secondMenuOpen: {
          opacity: 1,
          transition: {
            duration: 0
          }
        }
      }
    : {
        firstMenuOpenSecondMenuClosed: {
          opacity: 1,
          display: "inline-block",
          x: 0,
          transition: {
            ...enterObjectTransitionProperties,
            delay: 0.2,
            duration: 0
          }
        },
        firstMenuClosedSecondMenuOpen: {
          opacity: 0,
          x: "-1.5rem",
          transition: { ...enterObjectTransitionProperties, delay: 0 }
        },
        firstMenuClosed: {
          opacity: 0,

          transition: {
            ...enterObjectTransitionProperties,
            delay: 0.2
          }
        },
        secondMenuOpen: {
          opacity: 1,

          transition: { ...enterObjectTransitionProperties, delay: 0.3 }
        },
        secondMenuClosed: {
          opacity: 1,

          transition: { ...enterObjectTransitionProperties, delay: 0.2 }
        }
      }

  const handleClick = e => {
    // delay nav item links with sub-category by 1s so mobile menu can close before going to hash links
    window.disablePlpAutoScroll = false
    if (query.s) {
      e.preventDefault()
      setTimeout(
        () => {
          router.push(href + "?s=" + query.s, undefined, {
            shallow: window.location.pathname === href
          })
        },
        !isDesktop ? 1000 : 0
      )
    }
    onClick && onClick(e)
  }
  // add back logic here
  return (
    <m.div
      style={{
        width: "auto",
        display: "inline-block"
      }}
      variants={motionVariants}
      animate={menuOpenAnimation}
    >
      <Link href={{ pathname: href, query }} passHref sx={{ ...Styles }}>
        <span
          className={isActive ? "active" : "in-active"}
          id={id}
          role={role}
          onClick={handleClick}
          onKeyDown={onKeyDown}
          aria-haspopup={ariaHaspopup}
          aria-controls={ariaControls}
        >
          {children}
        </span>
      </Link>
    </m.div>
  )
}
