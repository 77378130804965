import {
  ArrowLeft,
  ButtonVariants,
  Colors,
  enterObjectTransitionProperties,
  FontWeight,
  H6,
  Label2,
  LinkVariants,
  PersonIcon,
  RedLogo,
  zIndices
} from "@sixty-six-north/ui-system"
import CartHeaderLink from "checkout/components/CartHeaderLink"
import Link from "components/Link"
import {
  NextLink,
  useDashboardLink,
  useHomeLink,
  useLoginLink
} from "components/Links"
import { useCustomerState } from "customer/CustomerHooks"
import { AnimatePresence, m } from "framer-motion"
import { LayoutState } from "layout/LayoutState"
import { useLayoutState } from "layout/LayoutStateHooks"
import {
  usePrismicNavigationLink,
  useTopLevelNavigation
} from "layout/NavigationHooks"
import _kebabCase from "lodash/kebabCase"
import { ParsedUrlQueryInput } from "querystring"
import React, { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { Box, Flex } from "theme-ui"
import { useIsMobile } from "utils/uiHelpers"
import { useAllCategories } from "../../../category/CategoryHooks"
import { MESSAGE_BAR_ELEMENT_ID } from "../../../components/GlobalMessageBar"
import { useFlag } from "../../../FeatureFlags"
import {
  useLocaleAwareLinks,
  usePreferredLanguage,
  useStoreContext
} from "../../../i18n/StoreHooks"
import { MenuItem, useAdditionalFilterLinks } from "../../../lagersala/Config"
import { usePrismicLinks } from "../../../prismic/PrismicLinkedDocumentContext"
import { PrismicColumnItem } from "../../../prismic/PrismicModels"
import { useCategoryForSlugApi } from "../../../prismic/slices/PrismicLink"
import { useAppDispatch } from "../../../State"
import { CartNotifications } from "../Layout/CartNotification"
import { SearchButton } from "../Layout/SearchButton"
import { HamburgerButton } from "./HamburgerButton"
import { MainList } from "./MainList"
import { MegaList } from "./MegaList"
import { Nav } from "./Nav"
import { NavigationAdSpot } from "./NavigationAdSpot"
import { NavigationBar } from "./NavigationBar"
import { NavbarDivider } from "./NavigationBarDivider"
import { NavigationTagsMenu } from "./NavigationTagsMenu"
import { NavItem } from "./NavItem"
import { NavItemLink } from "./NavItemLink"

const MenuStateMachine = (state: boolean) => {
  let stateChangedTo = true

  switch (state) {
    case false:
      stateChangedTo = true
      break
    case true:
      stateChangedTo = false
      break
    default:
      stateChangedTo = false
  }

  return stateChangedTo
}

const TrayItem = ({
  index,
  uid,
  href,
  query,
  label,
  menuOpen,
  secondMenuOpen,
  onClick = (e: React.MouseEvent | React.KeyboardEvent) => {}
}) => (
  <NavItem id={`nav-${uid}-Sub-menu-${index}`} role="none">
    <NavItemLink
      id={`menuitem-${uid}-Sub-menu-${index}`}
      role="menuitem"
      href={href}
      query={query}
      menuOpen={menuOpen}
      secondMenuOpen={secondMenuOpen}
      isChildren
      onClick={onClick}
    >
      {label}
    </NavItemLink>
  </NavItem>
)

interface LabelledCategoryLink {
  label: { text?: string }[]
  internal_url?: string | null
}

export const NavigationWrapper: FC = () => {
  const prismicNavLinkBuilder = usePrismicNavigationLinks()
  const disablePrismicNav = useFlag("features.disablePrismicNav", false)
  const isMobile = useIsMobile()

  // get some data
  const topLevelNavData = useTopLevelNavigation()

  const { generateCollectionMenuItems } = useAdditionalFilterLinks()
  const { menuOpen, secondMenuOpen } = useLayoutState()

  const store = useStoreContext()
  const freeShippingThreshold = store.freeShippingThreshold

  const dispatch = useAppDispatch()

  const [activeMenus, setActiveMenus] = useState<string[]>([]) // array that captures the ids of active menus
  const [subMenu, setSubMenu] = useState<string | null>(null) // captures the current submenu
  const wrapperRef = useRef<HTMLDivElement>(null) // used to detect clicks outside of component

  // account and login links
  const customer = useCustomerState()
  const dashboardLink = useDashboardLink()
  const loginLink = useLoginLink()
  const accountLink = customer ? dashboardLink : loginLink

  // translations
  const { t } = useTranslation("layout")
  const categories = useAllCategories()
  const lang = usePreferredLanguage()
  const linkBuilder = useLocaleAwareLinks()
  const categoryForSlug = useCategoryForSlugApi()

  const motionVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        stiffness: enterObjectTransitionProperties.stiffness,
        damping: enterObjectTransitionProperties.damping,
        duration: 1,
        type: "spring"
      }
    },
    closed: {
      opacity: 0,
      x: "3.5rem",
      transition: {
        stiffness: enterObjectTransitionProperties.stiffness,
        damping: enterObjectTransitionProperties.damping,
        duration: 1,
        type: "spring"
      }
    }
  }

  const resetMenus = () => {
    // close all menus and empty activeMenus array
    setActiveMenus([])
    setSubMenu(null)
    dispatch(LayoutState.actions.closeAllMenus())
  }

  const getItemsByCategory = (uid: string): LabelledCategoryLink[] => {
    const groupedCategories = categories.filter(
      cat => cat.ancestors.length && cat.ancestors.find(a => a.key === uid)
    )
    return groupedCategories.map(cat => {
      const category = categoryForSlug.categoryForSlug(cat.slug)
      const categoryLink = category ? linkBuilder.categoryLink(category) : null
      return {
        label: [{ text: category?.name[lang] }],
        internal_url: categoryLink?.href.pathname
      }
    })
  }

  const useOutsideAlerter = () => {
    useEffect(() => {
      if (!menuOpen) return

      // Reset menu if clicked on outside of element
      const handleClickOutside = (e: React.MouseEvent | Event) => {
        if ((e.target as Element)?.closest(`#${MESSAGE_BAR_ELEMENT_ID}`)) return
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(e.target as Node)
        ) {
          resetMenus()
        }
      }

      // Bind the event listener to both mouse and key events
      window.addEventListener("mouseup", handleClickOutside)
      window.addEventListener("keyup", handleClickOutside)
      return () => {
        // Unbind the event listener to clean up
        window.removeEventListener("mouseup", handleClickOutside)
        window.removeEventListener("keyup", handleClickOutside)
      }
    }, [menuOpen])
  }

  const updateActiveMenus = (state: boolean, menuId: string) => {
    if (state === true) {
      // add menuId from activeMenus
      setActiveMenus([...activeMenus, menuId])
    } else if (state === false) {
      // remove menuId from activeMenus
      setActiveMenus(activeMenus.filter(item => item !== menuId))
    }
  }

  const toggleMegaMenu = (
    _e: React.MouseEvent | React.KeyboardEvent,
    menuId: string
  ): void => {
    if (menuOpen) {
      return resetMenus()
    }

    const nextState = MenuStateMachine(menuOpen)

    if (nextState) {
      dispatch(LayoutState.actions.openTray())
    } else if (!nextState) {
      dispatch(LayoutState.actions.closeTray())
    }

    updateActiveMenus(nextState, menuId)
  }

  const toggleSubMenu = (
    e: React.MouseEvent | React.KeyboardEvent,
    menuId: string
  ): void => {
    e.preventDefault()
    const nextState = MenuStateMachine(secondMenuOpen)

    if (nextState) {
      dispatch(LayoutState.actions.openSecondTray())
    } else if (nextState === false) {
      dispatch(LayoutState.actions.closeSecondTray())
    }

    /* 
      I haven't come up with single solution (yet) that takes care of 
      opening and closing menus for both small and large screens, so for 
      now I fork the logic based on viewport size.
      */
    if (!isMobile) {
      if (activeMenus.includes(menuId)) {
        // menu is already open, remove it from activeMenus to close it
        setActiveMenus([])
        dispatch(LayoutState.actions.closeAllMenus())
      } else {
        // menu is not yet active, add it to activeMenus to open it
        setActiveMenus([menuId])
      }
    } else {
      // remove menuId from activeMenus
      updateActiveMenus(nextState, menuId)
    }
  }

  const a11yClick = (e: React.KeyboardEvent) => {
    return e.key === "Enter" || e.key === "Space"
  }

  const doEscape = (e: KeyboardEvent): void => {
    if (e.code === "Escape") {
      resetMenus()
    }
  }

  useEffect(() => {
    let windowWidth = window.innerWidth

    const handleResetMenu = () => {
      if (window.innerWidth !== windowWidth) {
        setTimeout(resetMenus, 150)
      }
      windowWidth = window.innerWidth
    }

    window.addEventListener("resize", handleResetMenu)
    return () => {
      window.removeEventListener("resize", handleResetMenu)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", doEscape, false)

    return () => {
      document.removeEventListener("keydown", doEscape, false)
    }
  }, [])

  useOutsideAlerter()

  return (
    <Flex
      ref={wrapperRef}
      sx={{
        width: "100%",
        position: "sticky",
        top: 0,
        zIndex: [zIndices.megaMenu, null, null, zIndices.megaMenu]
      }}
    >
      <NavigationBar>
        <Flex
          sx={{
            display: ["flex", null, null, "none"],
            height: "100%",
            width: "100%"
          }}
        >
          <HamburgerButton
            toggle={e => toggleMegaMenu(e, "nav-main")}
            aria-controls="mobile-menu"
            aria-haspopup={true}
            aria-expanded={menuOpen}
          >
            <span
              sx={{
                variant: `links.${LinkVariants.link_h6}`,
                color: "inherit"
              }}
            >
              {menuOpen ? t("close") : "Menu"}
            </span>
          </HamburgerButton>
          <Flex
            sx={{
              flexFlow: "row wrap",
              alignItems: "center",
              justifyContent: "center",
              ml: "auto"
            }}
          >
            <SearchButton id="mobile-search" onClick={resetMenus} />
            <CartHeaderLink onClick={resetMenus} />
          </Flex>
          <NavbarDivider />
        </Flex>
        <Flex
          sx={{
            height: 88,
            width: "auto",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Link
            aria-label={t("home")}
            {...useHomeLink()}
            onClick={e => resetMenus()}
            sx={{ lineHeight: 0 }}
          >
            <RedLogo title={"66ºNorth"} />
          </Link>
        </Flex>

        <NavbarDivider
          sx={{
            display: ["none", null, null, "flex"],
            height: "100%"
          }}
        />
        <Nav
          id="site-nav"
          ariaLabel="Main Navigation"
          menuOpen={menuOpen}
          secondMenuOpen={secondMenuOpen}
        >
          <MainList
            id="menubar-main"
            ariaLabel="Main Menu"
            menuOpen={menuOpen}
            secondMenuOpen={secondMenuOpen}
          >
            <NavItem
              role="none"
              id="nav-home"
              sx={{
                display: ["flex", null, null, "none"],
                flexDirection: "column",
                pt: 8
              }}
            >
              <NavItemLink
                id="menuitem-home"
                role="menuitem"
                href="/"
                menuOpen={menuOpen}
                secondMenuOpen={secondMenuOpen}
                onClick={e => toggleMegaMenu(e, "nav-main")}
              >
                {t("home")}
              </NavItemLink>
            </NavItem>
            {topLevelNavData.items.map(topLevelNav => {
              const url =
                prismicNavLinkBuilder.createMainLink(topLevelNav.main_url?.id)
                  ?.as || topLevelNav.main_url?.url
              const tagsColumn = topLevelNav.document.data?.tags_column || []
              const additionalFilterMenuItem = generateCollectionMenuItems(
                topLevelNav.document.uid
              )
              const uid = topLevelNav.document.uid
              const parentCategorySlug = uid.replace("-", "")
              const toMenuItem: (item) => MenuItem = item => {
                const hrefData = prismicNavLinkBuilder.createColumnLink(
                  item,
                  parentCategorySlug
                )
                const href = hrefData?.as
                const query = hrefData?.href?.query || {}
                const label = item.label && item.label.map(l => l.text)[0]
                return { href, label, query }
              }

              const topLevelNavigationItems = [
                {
                  label: topLevelNav.document.data?.column_1_title,
                  items: [
                    ...(topLevelNav.document.data?.column_1?.map(toMenuItem) ||
                      []),
                    ...additionalFilterMenuItem
                  ]
                },
                {
                  label: topLevelNav.document.data?.column_2_title,
                  items: (disablePrismicNav
                    ? getItemsByCategory(topLevelNav.document.uid)
                    : topLevelNav.document.data?.column_2
                  )?.map(toMenuItem)
                },
                {
                  label: topLevelNav.document.data?.column_3_title,
                  items: topLevelNav.document.data?.column_3?.map(toMenuItem)
                },
                {
                  label: topLevelNav.document.data?.column_4_title,
                  items: topLevelNav.document.data?.column_4?.map(toMenuItem)
                },
                {
                  label: topLevelNav.document.data?.column_5_title,
                  items: topLevelNav.document.data?.column_5?.map(toMenuItem)
                },
                isMobile
                  ? {
                      label: topLevelNav.document.data?.tags_column_title,
                      items:
                        topLevelNav.document.data?.tags_column?.map(toMenuItem)
                    }
                  : {}
              ]
                .filter(column => column.label)
                .map(column => {
                  const columnItems: MenuItem[] = column?.items || []
                  const parentSlug = _kebabCase(columnItems[0]?.label)
                  const subMenuEnabled = subMenu === `menuitem-${parentSlug}`

                  return {
                    column,
                    columnItems,
                    subMenuEnabled,
                    parentSlug,
                    uid
                  }
                })
              return (
                url && (
                  <NavItem
                    key={topLevelNav.document.uid}
                    role="none"
                    id={`nav-${topLevelNav.document.uid}`}
                  >
                    <NavItemLink
                      menuOpen={menuOpen}
                      secondMenuOpen={secondMenuOpen}
                      role="menuitem"
                      id={`menuitem-${topLevelNav.document.uid}`}
                      href={url}
                      isActive={activeMenus.includes(
                        `menu-${topLevelNav.document.uid}`
                      )}
                      onClick={e =>
                        toggleSubMenu(e, `menu-${topLevelNav.document.uid}`)
                      }
                      onKeyDown={e =>
                        a11yClick(e) &&
                        toggleSubMenu(e, `menu-${topLevelNav.document.uid}`)
                      }
                      ariaHaspopup
                      ariaControls={`menu-${topLevelNav.document.uid}`}
                    >
                      {topLevelNav.title}
                    </NavItemLink>

                    {/* mega list */}

                    {menuOpen && (
                      <MegaList
                        id={`menu-${topLevelNav.document.uid}`}
                        menuOpen={menuOpen}
                        secondMenuOpen={secondMenuOpen}
                        activeState={activeMenus.includes(
                          `menu-${topLevelNav.document.uid}`
                        )}
                      >
                        <Flex sx={{ flexDirection: "column" }}>
                          <Flex
                            sx={{
                              flexDirection: ["column", null, null, "row"],
                              flex: 1,
                              mb: "auto"
                            }}
                          >
                            <NavItem
                              id={`nav-${topLevelNav.document.uid}-back`}
                              isBack={true}
                            >
                              <button
                                sx={{
                                  variant: `buttons.${ButtonVariants.accessible}`,
                                  textAlign: "left"
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                  if (subMenu) setSubMenu(null)
                                  else
                                    toggleSubMenu(
                                      e,
                                      `menu-${topLevelNav.document.uid}`
                                    )
                                }}
                                onKeyDown={e => {
                                  a11yClick(e)
                                  if (subMenu) setSubMenu(null)
                                  else
                                    toggleSubMenu(
                                      e,
                                      `menu-${topLevelNav.document.uid}`
                                    )
                                }}
                              >
                                <Flex sx={{ color: "blue.0" }}>
                                  <Box sx={{ mr: 12 }}>
                                    <ArrowLeft />
                                  </Box>
                                  <Label2>{t("back")}</Label2>
                                </Flex>
                              </button>
                            </NavItem>
                            {/* child items */}
                            {topLevelNavigationItems.map(
                              (
                                {
                                  column,
                                  columnItems,
                                  subMenuEnabled,
                                  parentSlug,
                                  uid
                                },
                                colIdx
                              ) => (
                                <Flex
                                  key={colIdx}
                                  sx={{
                                    flexDirection: "column",
                                    mr: [0, null, 48, 64, 144]
                                  }}
                                >
                                  {column && column.label && !isMobile && (
                                    <Label2
                                      sx={{
                                        color: "black.1",
                                        fontWeight: FontWeight.textBold,
                                        mt: [12, null, null, 48],
                                        mb: 8
                                      }}
                                    >
                                      {column.label.map(label => label.text)[0]}
                                    </Label2>
                                  )}
                                  {columnItems?.map((ti, tix) => {
                                    const { href, label, query } = ti
                                    const slug = _kebabCase(
                                      label?.replace(/^\d+/, "")
                                    )

                                    const commonProps = {
                                      uid,
                                      index: tix,
                                      menuOpen,
                                      secondMenuOpen,
                                      onClick: e =>
                                        toggleMegaMenu(e, "nav-main")
                                    }
                                    const displaySubCategory =
                                      subMenu === null && !subMenuEnabled

                                    if (!isMobile) {
                                      return (
                                        <React.Fragment key={`submenu-${slug}`}>
                                          {href && (
                                            <TrayItem
                                              {...commonProps}
                                              href={href}
                                              query={query}
                                              label={label}
                                            />
                                          )}
                                        </React.Fragment>
                                      )
                                    }
                                    //mobile 2nd submenu behaviour
                                    if (tix === 0) {
                                      return (
                                        <React.Fragment key={`submenu-${slug}`}>
                                          <AnimatePresence>
                                            <m.div
                                              sx={{
                                                mb: displaySubCategory ? 12 : 0,
                                                height: displaySubCategory
                                                  ? "auto"
                                                  : 0,
                                                visibility: displaySubCategory
                                                  ? "visible"
                                                  : "hidden"
                                              }}
                                              animate={
                                                displaySubCategory
                                                  ? "open"
                                                  : "closed"
                                              }
                                              initial={false}
                                              variants={{
                                                ...motionVariants,
                                                closed: {
                                                  ...motionVariants.closed,
                                                  x: "-1.5rem"
                                                }
                                              }}
                                            >
                                              <NavItemLink
                                                menuOpen={menuOpen}
                                                role="menuitem"
                                                id={`menuitem-${slug}`}
                                                href={url}
                                                onClick={e => {
                                                  e.preventDefault()
                                                  setSubMenu(
                                                    `menuitem-${parentSlug}`
                                                  )
                                                }}
                                                onKeyDown={e => {
                                                  e.preventDefault()
                                                  a11yClick(e)
                                                  setSubMenu(
                                                    `menuitem-${parentSlug}`
                                                  )
                                                }}
                                                ariaHaspopup
                                                ariaControls={`menuitem-${slug}`}
                                              >
                                                {
                                                  column?.label?.map(
                                                    label => label.text
                                                  )[0]
                                                }
                                              </NavItemLink>
                                            </m.div>
                                          </AnimatePresence>
                                          <AnimatePresence
                                            key={`${colIdx}-${tix}`}
                                          >
                                            <m.div
                                              variants={motionVariants}
                                              animate={
                                                subMenuEnabled
                                                  ? "open"
                                                  : "closed"
                                              }
                                              sx={{
                                                height: subMenuEnabled
                                                  ? "auto"
                                                  : 0,
                                                visibility: subMenuEnabled
                                                  ? "visible"
                                                  : "hidden"
                                              }}
                                            >
                                              {href && (
                                                <TrayItem
                                                  {...commonProps}
                                                  href={href}
                                                  query={query}
                                                  label={label}
                                                />
                                              )}
                                            </m.div>
                                          </AnimatePresence>
                                        </React.Fragment>
                                      )
                                    }
                                    return (
                                      <AnimatePresence key={`${colIdx}-${tix}`}>
                                        <m.div
                                          variants={motionVariants}
                                          animate={
                                            subMenuEnabled ? "open" : "closed"
                                          }
                                          sx={{
                                            height: subMenuEnabled ? "auto" : 0,
                                            visibility: subMenuEnabled
                                              ? "visible"
                                              : "hidden"
                                          }}
                                        >
                                          {href && (
                                            <TrayItem
                                              {...commonProps}
                                              href={href}
                                              query={query}
                                              label={label}
                                            />
                                          )}
                                        </m.div>
                                      </AnimatePresence>
                                    )
                                  })}
                                </Flex>
                              )
                            )}
                          </Flex>
                          <Flex
                            sx={{
                              flexDirection: ["column", null, null, "row"],
                              justifySelf: "flex-end",
                              display: ["none", null, null, "flex"],
                              py: 16
                            }}
                          >
                            <Label2 sx={{ color: `${Colors.grey}.1` }}>
                              {t("layout:keepingIcelandWarm")}
                            </Label2>
                            <H6
                              sx={{
                                px: 24,
                                display: ["none", null, null, "block"]
                              }}
                            >
                              ·
                            </H6>
                            <Label2 sx={{ color: `${Colors.grey}.1` }}>
                              {t("layout:freeShippingMessage", {
                                amount: freeShippingThreshold
                              })}
                            </Label2>
                          </Flex>
                        </Flex>
                        {tagsColumn.length === 0 && (
                          <NavigationAdSpot
                            document={topLevelNav.document}
                            onClick={_e => resetMenus()}
                          />
                        )}
                        {tagsColumn.length > 0 && !isMobile && (
                          <NavigationTagsMenu
                            document={topLevelNav.document}
                            onClick={_e => resetMenus()}
                          />
                        )}
                      </MegaList>
                    )}
                  </NavItem>
                )
              )
            })}
            {isMobile && (
              <NavItem role="none">
                <NavItemLink
                  id="menuitem-account"
                  role="menuitem"
                  href={accountLink.as}
                  menuOpen={menuOpen}
                  secondMenuOpen={secondMenuOpen}
                  onClick={_e => resetMenus()}
                >
                  {t("account")}
                </NavItemLink>
              </NavItem>
            )}
          </MainList>
        </Nav>

        {!isMobile && (
          <Flex
            sx={{
              display: ["none", null, null, "flex"],
              height: "100%",
              alignItems: "center",
              flex: 1
            }}
          >
            <NavbarDivider sx={{ mr: "auto" }} />
            <SearchButton id="desktop-search" onClick={resetMenus} />

            <Link
              id="menuitem-account"
              href={accountLink.as}
              aria-label={t("account")}
              onClick={_e => resetMenus()}
              sx={{
                mr: 24,
                position: "relative",
                variant: `links.${LinkVariants.navBar}`,
                borderBottomColor: "transparent",
                ":hover": { borderBottomColor: "transparent" }
              }}
            >
              <PersonIcon width={22} height={22} />
            </Link>
            <CartHeaderLink onClick={resetMenus} />
          </Flex>
        )}

        <CartNotifications />
      </NavigationBar>
    </Flex>
  )
}

const categorySlug = {
  en: { men: "men", women: "women", kids: "kids" },
  is: { men: "karlar", women: "konur", kids: "krakkar" }
}

export const usePrismicNavigationLinks = () => {
  const linkBuilder = useLocaleAwareLinks()
  const navLinks = usePrismicNavigationLink()
  const contentLinks = usePrismicLinks()
  const categoryForSlug = useCategoryForSlugApi()
  const language = usePreferredLanguage()

  const createMainLink = (documentId: string | undefined): NextLink | null => {
    const navLink = navLinks.linkForDocumentId(documentId)
    const docLink = contentLinks.linkForDocumentId(documentId)
    return navLink || docLink || null
  }

  const createColumnLink = (
    item: PrismicColumnItem,
    parentCategorySlug?: string
  ): NextLink | null => {
    if (item.prismic_link && item.prismic_link.id) {
      const documentId = item.prismic_link.id
      const navLink = navLinks.linkForDocumentId(documentId)
      const docLink = contentLinks.linkForDocumentId(documentId)
      return navLink || docLink || null
    } else if (item.commerce_tools_category) {
      const prismicCategory = item.commerce_tools_category
      const category = categoryForSlug.categoryForSlug(prismicCategory.slug)
      if (item.commerce_tools_shelf_name) {
        return category
          ? linkBuilder.shelfLink(item.commerce_tools_shelf_name, category)
          : null
      }
      const categoryLink = category ? linkBuilder.categoryLink(category) : null
      return categoryLink
    } else if (item.internal_url) {
      const link = linkBuilder.genericLink(item.internal_url)
      return link
    } else if (item.tag_filter && parentCategorySlug) {
      return linkBuilder.tagLink(
        item.tag_filter,
        categorySlug[language][parentCategorySlug]
      )
    } else {
      return null
    }
  }
  return {
    createMainLink,
    createColumnLink
  }
}
