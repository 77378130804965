//jsxhook
import {
  ImageVariants,
  ImgixAspectRatio,
  LinkVariants,
  PromotionalCard
} from "@sixty-six-north/ui-system"
import Link from "next/link"
import { PrismicDocumentAdapter } from "prismic/PrismicAdapters"
import { PrismicDocument } from "prismic/PrismicModels"
import { PrismicImgixImage } from "prismic/slices/PrismicImgixImage"
import React, { FC } from "react"
import { Box, Flex } from "theme-ui"
import { usePrismicNavigationLinks } from "./NavigationWrapper"

interface Props {
  document: PrismicDocument
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}
export const NavigationAdSpot: FC<Props> = ({ document, onClick }) => {
  const prismicNavLinkBuilder = usePrismicNavigationLinks()
  const adSpot = useAdSpot(document)
  const url = prismicNavLinkBuilder.createMainLink(adSpot.link?.id)?.as

  return (
    <Flex
      sx={{
        padding: 48,
        bg: "white.0",
        ml: "auto",
        display: ["none", null, null, null, "block"],
        height: 504
      }}
    >
      <Box
        sx={{
          width: 504,
          height: 504,
          bg: "grey.2"
        }}
      >
        {adSpot && url && (
          <PromotionalCard
            title={adSpot.sectionTitle("")}
            section={adSpot.title("")}
            imageAlt={adSpot.title("")}
            ratio={ImageVariants.ratio_1x1}
            mode="dark"
            imageNode={
              <PrismicImgixImage
                image={adSpot.image}
                aspectRatio={ImgixAspectRatio.ar1x1}
              />
            }
          >
            <Link href={url} passHref>
              <span
                sx={{ variant: `links.${LinkVariants.card}` }}
                onClick={onClick}
              >
                {adSpot.linkLabel("")}
              </span>
            </Link>
          </PromotionalCard>
        )}
      </Box>
    </Flex>
  )
}

const useAdSpot = (document: PrismicDocument) => {
  const {
    internalAdvertText: sectionTitle,
    internalAdvertTitle: title,
    image,
    internalAdvertLink: link,
    internalAdvertLinkLabel: linkLabel
  } = new PrismicDocumentAdapter(document).data
  return {
    sectionTitle,
    title,
    imageAlt: title,
    image,
    link,
    linkLabel
  }
}
