//jsxhook
import {
  enterObjectTransitionProperties,
  Shadows,
  zIndices
} from "@sixty-six-north/ui-system"
import { AnimatePresence, m } from "framer-motion"
import { LayoutState } from "layout/LayoutState"
import { useLayoutState } from "layout/LayoutStateHooks"
import { FC, useRef, ReactNode } from "react"

import { Box } from "theme-ui"
import { useIsMobile } from "utils/uiHelpers"
import { useAppDispatch } from "../../../State"

interface Props {
  menuOpen: boolean
  children?: ReactNode
}

export const PageContent: FC<Props> = ({ children }) => {
  const PageRef = useRef<HTMLInputElement>(null)
  const innerRef = useRef<HTMLInputElement>(null)
  const isMobile = useIsMobile()
  const dispatch = useAppDispatch()
  const { menuOpen, previousScroll } = useLayoutState()

  const variants = isMobile
    ? {
        closed: {
          scale: 1,
          transition: { ...enterObjectTransitionProperties, delay: 0.2 }
        },
        open: {
          scale: 0.5,
          transition: { ...enterObjectTransitionProperties, delay: 0.2 }
        }
      }
    : {
        closed: {
          scale: 1
        },
        open: {
          scale: 1
        }
      }

  return (
    <AnimatePresence>
      <m.div
        ref={PageRef}
        animate={menuOpen ? "open" : "closed"}
        initial={false}
        variants={variants}
        style={{
          originX: 1.5,
          originY: 0.5,
          willChange: "overflow, height, scale, transform"
        }}
        sx={{
          bg: "white.0",
          width: "100%",
          zIndex: menuOpen && isMobile ? zIndices.overlay : zIndices.page,
          position: "relative"
        }}
        onAnimationStart={() => {
          if (menuOpen && isMobile) {
            window.scroll(0, 0)
            document.body.style.overflow = "hidden"
            if (innerRef && innerRef.current && PageRef && PageRef.current) {
              const translate = previousScroll * -1
              innerRef.current.style.transform = `translateY(${translate}px)`
              PageRef.current.style.overflow = "hidden"
              PageRef.current.style.height =
                Math.ceil(window.innerHeight) + "px"
            } else {
              document.body.style.overflow = ""
            }
          }
        }}
        onAnimationComplete={() => {
          if (!menuOpen && isMobile) {
            setTimeout(() => window.scroll(0, previousScroll))

            if (innerRef && innerRef.current && PageRef && PageRef.current) {
              innerRef.current.style.transform = "translateY(0)"
              PageRef.current.style.overflow = "visible"
              PageRef.current.style.height = "auto"
              document.body.style.overflow = ""
            }
          }
        }}
      >
        <Box
          as="button"
          aria-expanded={menuOpen}
          aria-label="Toggle mobile menu"
          onClick={() => dispatch(LayoutState.actions.closeAllMenus())}
          sx={{
            width: "100%",
            height: "100%",
            bg: "transparent",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100,
            display: menuOpen ? "block" : "none",
            borderStyle: "solid",
            borderColor: "grey.3",
            borderWidth: "1px",
            boxShadow: Shadows.large
          }}
        ></Box>
        <Box ref={innerRef} sx={{ willChange: "translate", minHeight: "100%" }}>
          {children}
        </Box>
      </m.div>
    </AnimatePresence>
  )
}
