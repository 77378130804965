//jsxhook

import {
  FontWeight,
  StandardXAxisSpace,
  Label2,
  Column,
  Row,
  ProductPriceColumn,
  ScreenReaderOnly
} from "@sixty-six-north/ui-system"
import { AnimatePresence, m } from "framer-motion"

import { useTranslation } from "react-i18next"
import React, { useEffect, ReactNode } from "react"
import { Box } from "theme-ui"

export const ShoppingBagToast: React.FC<{
  showNotification: boolean
  timeout?: number
  clearNotification: () => void
  children?: ReactNode
}> = ({ showNotification, timeout = 5000, clearNotification, children }) => {
  useEffect(() => {
    let timeoutHandle: NodeJS.Timeout
    if (showNotification) {
      timeoutHandle = setTimeout(() => {
        clearNotification()
      }, timeout)
    }
    return () => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
      }
    }
  }, [showNotification])

  return (
    <Box
      sx={{
        position: ["fixed", "fixed", "absolute"],
        width: ["100%", "100%", 375],
        top: 88,
        mt: 0,
        right: [0, null, 24, 48, 48, StandardXAxisSpace],
        display: "flex",
        zIndex: 10
      }}
      className="shoppingBagToast"
    >
      <AnimatePresence initial={false}>
        {React.Children.count(children) > 0 && showNotification && (
          <m.div
            key={`shopping-bag-notifcation`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, display: "flex" }}
            exit={{ opacity: 0 }}
            sx={{ width: ["100%", "100%", 375] }}
          >
            <ScreenReaderOnly>
              <span aria-live="assertive">Product added to bag</span>
            </ScreenReaderOnly>
            {children}
          </m.div>
        )}
      </AnimatePresence>
    </Box>
  )
}

export const ShoppingBagNotification: React.FC<{
  product: {
    name: string
    price: string
    discountedPrice: string
    size: string
    color: string
  }
  image: React.ReactNode
  link: React.ReactNode
}> = ({
  product: { name, price, discountedPrice, size, color },
  image,
  link
}) => {
  const { t } = useTranslation("layout")

  return (
    <Box
      className="shoppingBagNotification"
      sx={{
        width: ["100%", "100%", 375],
        backgroundColor: "white",
        boxShadow: "small"
      }}
    >
      <Column
        sx={{
          marginTop: 24,
          marginBottom: 24,
          marginLeft: 24,
          marginRight: 24
        }}
      >
        <Row>
          <Box
            sx={{
              pr: 24
            }}
          >
            {image}
          </Box>
          <Column sx={{ width: "100%", mb: 26 }}>
            <Row sx={{ mb: 8 }}>
              <Box sx={{ color: "grey.0" }}>
                <Label2 sx={{ fontWeight: FontWeight.textBold }}>{name}</Label2>
              </Box>
              <Box sx={{ color: "grey.1", marginLeft: "auto" }}>
                <ProductPriceColumn
                  price={price}
                  discountPrice={discountedPrice}
                />
              </Box>
            </Row>
            <Row sx={{ mb: 4 }}>
              <Box sx={{ color: "grey.1" }}>
                <Label2>{t("size", { defaultValue: "Size" })}</Label2>
              </Box>
              <Box sx={{ color: "grey.0", ml: 8 }}>
                <Label2>{size}</Label2>
              </Box>
            </Row>
            <Row sx={{ mb: 4 }}>
              <Box sx={{ color: "grey.1" }}>
                <Label2>{t("color", { defaultValue: "Color" })}</Label2>
              </Box>
              <Box sx={{ color: "grey.0", ml: 8 }}>
                <Label2>{color}</Label2>
              </Box>
            </Row>
          </Column>
        </Row>
        <Row>{link}</Row>
      </Column>
    </Box>
  )
}
