//jsxhook

import React, { ReactNode } from "react"
import { Box, Flex } from "theme-ui"
import { NotificationIndicator } from "@sixty-six-north/ui-system"
interface ShoppingBagProps {
  itemsInShoppingBag: number
  highlight: boolean
  children?: ReactNode
}

export const ShoppingBagIndicator: React.FC<ShoppingBagProps> = ({
  itemsInShoppingBag,
  highlight,
  children
}) => {
  return (
    <Flex
      sx={{ flexDirection: "row", alignItems: "center", position: "relative" }}
    >
      <Box
        sx={{
          display: "none",
          "@media screen and (min-width: 62em)": {
            display: "block",
            mr: 8
          }
        }}
      >
        {children}
      </Box>
      {itemsInShoppingBag > 0 && (
        <NotificationIndicator
          items={itemsInShoppingBag}
          highlight={highlight}
        />
      )}
    </Flex>
  )
}
