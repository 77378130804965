import {
  Colors,
  enterObjectTransitionProperties,
  StandardXAxisSpace,
  zIndices
} from "@sixty-six-north/ui-system"
import { m } from "framer-motion"
import { FC, ReactNode } from "react"
import { ThemeUIStyleObject } from "theme-ui"
import { useIsDesktop, useIsMobile } from "utils/uiHelpers"

interface Props {
  id: string
  activeState: boolean
  menuOpen?: boolean
  secondMenuOpen?: boolean
  children?: ReactNode
}

export const MegaList: FC<Props> = ({
  activeState,
  id,
  menuOpen,
  secondMenuOpen,
  children
}) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()

  const Styles: ThemeUIStyleObject = {
    position: "absolute",
    zIndex: [
      activeState ? zIndices.page : 0, // we swap these around to avoid link clashes
      null,
      null,
      zIndices.megaMenu
    ],

    flexDirection: ["column", null, null, "row"],
    left: 0,
    right: 0,
    top: [24, null, null, 88],
    width: "100%",
    height: ["100%", null, null, 600],
    bg: `${Colors.white}.0`,
    pl: [0, 0, 0, 48, 48, StandardXAxisSpace]
  }

  // is the first menu, and the first menu is open
  // is the first menu, and the second menu is closed
  // is the first menu, and the second menu is open
  // is the second menu, and the second menu is open
  // is the second menu, and the second menu is closed
  let menuOpenAnimation = "secondMenuClosed"
  if (menuOpen && secondMenuOpen && activeState) {
    menuOpenAnimation = "secondMenuOpen"
  } else if (menuOpen && secondMenuOpen === false && activeState === false) {
    menuOpenAnimation = "secondMenuClosed"
  } else if (isDesktop && activeState) {
    menuOpenAnimation = "secondMenuOpen"
  } else if (isDesktop && activeState === false) {
    menuOpenAnimation = "secondMenuClosed"
  } else {
    menuOpenAnimation = "closed"
  }

  const motionVariants = {
    secondMenuOpen: {
      opacity: 1,
      x: 0,
      display: "flex",
      transition: {
        stiffness: enterObjectTransitionProperties.stiffness,
        damping: enterObjectTransitionProperties.damping,
        delay: isMobile ? 0.2 : 0,
        duration: isMobile ? 0.3 : 0,
        type: isMobile ? "spring" : "none"
      }
    },
    secondMenuClosed: {
      opacity: 0,
      x: isMobile ? "1.5rem" : 0,
      display: "none",

      transition: {
        stiffness: enterObjectTransitionProperties.stiffness,
        damping: enterObjectTransitionProperties.damping,
        delay: isMobile ? 0.2 : 0,
        duration: isMobile ? 0.3 : 0,
        type: isMobile ? "spring" : "none"
      }
    },
    closed: {
      opacity: 0,
      x: 0,
      display: "none",
      transition: {
        duration: 0
      }
    }
  }

  //const AnimatedBox = motion(Box)

  return (
    <m.ul
      role="menu"
      id={id}
      aria-labelledby={id}
      sx={Styles}
      animate={menuOpenAnimation}
      variants={motionVariants}
      initial={{ opacity: 0, display: "none" }}
      style={{ opacity: 0, display: "none" }}
    >
      {children}
    </m.ul>
  )
}
