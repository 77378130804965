//jsxhook
import { Colors, FontWeight, H6, Label2 } from "@sixty-six-north/ui-system"
import { useStoreContext } from "i18n/StoreHooks"
import { MenuItem } from "lagersala/Config"
import { PrismicDocument } from "prismic/PrismicModels"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"
import { usePrismicNavigationLinks } from "./NavigationWrapper"
import { NavItem } from "./NavItem"
import { NavItemLink } from "./NavItemLink"

interface Props {
  document: PrismicDocument
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}
export const NavigationTagsMenu: FC<Props> = ({ document, onClick }) => {
  const prismicNavLinkBuilder = usePrismicNavigationLinks()
  const { t } = useTranslation("layout")
  const store = useStoreContext()
  const freeShippingThreshold = store.freeShippingThreshold

  return (
    <Flex
      sx={{
        px: [0, null, null, 32, 64],
        mt: [28, null, null, 0],
        mb: [40, null, null, 0],
        ml: [0, null, null, "auto"],
        display: "block",
        backgroundColor: ["none", null, null, `${Colors.grey}.4`]
      }}
    >
      <Flex
        sx={{
          flexDirection: ["column", null, null, "row"],
          flex: 1,
          mb: 100
        }}
      >
        {[
          {
            label: document.data?.tags_column_title,
            items: document.data?.tags_column
          }
        ].map((column, colIdx) => {
          const uid = document.uid
          const parentCategorySlug = uid.replace("-", "")
          const columnItems = column?.items?.map(item => {
            const hrefData = prismicNavLinkBuilder.createColumnLink(
              item,
              parentCategorySlug
            )

            const href = hrefData?.as
            const query = hrefData?.href?.query || {}
            const label = item.label && item.label.map(l => l.text)[0]
            return { href, query, label } as any
          })
          return (
            <Flex
              key={colIdx}
              sx={{
                flexDirection: "column",
                mr: [0, null, null, 96, 144]
              }}
            >
              {column && column.label && (
                <Label2
                  sx={{
                    color: "black.1",
                    fontWeight: FontWeight.textBold,
                    mt: [colIdx > 0 ? 40 : 12, null, null, 48],
                    mb: 8
                  }}
                >
                  {column.label.map(label => label.text)[0]}
                </Label2>
              )}
              {columnItems?.map((ti, tix) => {
                const { href, label, query } = ti
                return (
                  <React.Fragment key={`${colIdx}-${tix}`}>
                    {href && (
                      <NavItem id={`nav-${uid}-Sub-menu-${tix}`} role="none">
                        <NavItemLink
                          id={`menuitem-${uid}-Sub-menu-${tix}`}
                          role="menuitem"
                          query={query}
                          href={href as any}
                          onClick={onClick}
                          isChildren
                        >
                          {label}
                        </NavItemLink>
                      </NavItem>
                    )}
                  </React.Fragment>
                )
              })}
            </Flex>
          )
        })}
        <Flex
          sx={{
            flexDirection: "column",
            display: ["flex", null, null, "none"]
          }}
        >
          <Label2 sx={{ color: `${Colors.grey}.1` }}>
            {t("layout:keepingIcelandWarm")}
          </Label2>
          <Label2 sx={{ color: `${Colors.grey}.1` }}>
            {t("layout:freeShippingMessage", {
              amount: freeShippingThreshold
            })}
          </Label2>
        </Flex>
      </Flex>
    </Flex>
  )
}
