import { Box, BoxProps } from "theme-ui"
import React, { FC } from "react"

export const NavbarDivider: FC<BoxProps> = ({ ...props }) => {
  return (
    <Box
      sx={{
        width: "1px",
        height: "100%",
        borderRightStyle: "transparent",
        borderRightWidth: "1px",
        borderRightColor: "grey.3",
        marginLeft: 16,
        marginRight: 16
      }}
      {...props}
    />
  )
}
