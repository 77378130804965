//jsxhook
import { ButtonVariants, Label2, SearchIcon } from "@sixty-six-north/ui-system"
import { LayoutState } from "layout/LayoutState"
import { useLayoutState } from "layout/LayoutStateHooks"
import React from "react"
import { useTranslation } from "react-i18next"

import { Box, Flex } from "theme-ui"
import { useAppDispatch } from "../../../State"

export const SearchButton: React.FC<{ id: string; onClick(): void }> = ({
  id,
  onClick
}) => {
  const dispatch = useAppDispatch()
  const { searchOpen } = useLayoutState()
  const { t } = useTranslation("layout")

  return (
    <button
      aria-labelledby={id}
      aria-haspopup={true}
      aria-expanded={searchOpen}
      sx={{
        variant: `buttons.${ButtonVariants.accessible}`,
        color: "inherit",
        mr: 24
      }}
      onClick={() => {
        onClick()
        const toggleSearchState = LayoutState.actions.toggleSearch()
        dispatch(toggleSearchState)
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          color: "inherit"
        }}
      >
        <Box>
          <SearchIcon width={18} height={18} />
        </Box>

        <Label2
          hidden={true}
          id={id}
          sx={{
            display: "none"
          }}
        >
          {t("search")}
        </Label2>
      </Flex>
    </button>
  )
}
